import React from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/_contact.scss"
import "../styles/secondary_pages.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'





export default class FormResponse extends React.Component {


 render() {
  return (
   <Layout styleClass="contact secondary-form" >
    <SEO title="Our New Year's Gift to You!" />
    <section id="sct-pg-hdr" className="container ctr-headline secondary-headline">
     <h2>
      <span>Thank You</span> <wbr />
      for signing up for MSM Practice Health Check</h2>
     <p style={{ 'textAlign': 'center' }}>Our team will be contacting you very shortly using the information you provided. Looking forward to helping your practice thrive.</p>
    </section>

    <section className="ctr-form column-main">

    </section>







   </Layout >
  )
 }
}


